/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { Button, Checkbox, Icon, Input, Segment, Select, TextArea } from 'semantic-ui-react';
import { addEstateManualInputAsset, removeEstateManualInputAsset, setEstateManualInputField, removeEstateManualInputField } from '../../actions/estate-manual-input.actions';
import { v4 as uuid } from 'uuid';
import { InputItem } from '../shared/EstateInputItem';
import { SelectFamilyMember } from '../shared/SelectFamilyMember';
import { FamilyMembersModal } from "../modal/FamilyMembersModal";
// import { WhenWillItTransferToPrimaryBeneficiariesOptions } from "./TrustAndWillCommon";
import { usStates } from '../../data/usStates';

const SpousePrimaryBeneficiary = ({ docId, version }) => {
    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const client = useSelector(state => state.currentClient);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);

    if(estateManualInputRequest.estateSnapshotVersion && !version.includes(estateManualInputRequest.estateSnapshotVersion) ) {
        return <></>
    }

    const spouse = client?.estateContactsList?.find(c => c.relation === 'Spouse');

    const trustPrimaryBeneficiaries = estateManualInputAssets?.[`${docId}_beneficiaries`]
        ?.filter(item => estateManualInput?.[docId]?.[`beneficiaryType___${item.id}`] === 'primary_beneficiary')
        ?.map(item => estateManualInput?.[docId]?.[`beneficiary___${item.id}`]) || [];

    // Only render inputs if spouse is primary beneficiary
    if (!spouse || (spouse && !trustPrimaryBeneficiaries.includes(spouse._id))) {
        return <></>;
    }

    return (
        <>
            <p>In trust for spouse,</p>
            <InputItem indent={4} docType='ilit' label='Does spouse have 5 and 5 power?' field='spouseHas5and5Power' options={['Yes', 'No']} initialValue='No' version={version} />
            <InputItem indent={4} docType='ilit' label='Is spouse required to be income beneficiary, or is it discretionary?' field='spouseRequiredIncomeBeneficiary' options={['Yes', 'No']} initialValue='No' version={version} />
        </>
    )
}

const TrustAssets = ({ docId, version }) => {
    const dispatch = useDispatch();
    const maxTextAreaLength = 300
    const client = useSelector(state => state.currentClient);
    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const estateManualInputChange = useSelector(state => state.estateManualInputChange);

    // console.log('estateManualInputAssets:', estateManualInputAssets);
    // console.log('estateManualInput:', estateManualInput);
    // console.log('estateManualInputRequest:', estateManualInputRequest);
    // console.log('estateManualInputChange:', estateManualInputChange);

    const trustAssets = estateManualInputAssets?.[docId];

    const trustNameOptions = client?.estateContactsList
        ?.filter(c => c.relation === 'Trust')
        ?.map(c => ({ key: c._id, value: c._id, text: `${c.firstName}` }))
        || [];

    const self = client?.estateContactsList?.filter(itm => itm.relation === 'Self')[0];
    const selfFullName = `${self.firstName} ${self.lastName}`;
    const spouse = client?.estateContactsList?.filter(itm => itm.relation === 'Spouse')[0] || false;
    const spouseFullName = spouse ? `${spouse.firstName} ${spouse.lastName}` : 'N/A';

    const firstDeathTriggerOptions = [
        'Upon death of first settlor',
        `Upon death of ${selfFullName} if spouse (${spouseFullName}) survives them`,
        `Upon death of ${spouseFullName} if spouse (${selfFullName}) survives them`,
        'Upon death of either settlor',
        'Upon death of either settlor, during lifetime of surviving settlor',
        'During lifetime of surviving settlor',
        'Other',
    ];
    const secondDeathTriggerOptions = [
        'Upon death of surviving settlor',
        `Upon death of surviving spouse`,
        `Upon death of ${selfFullName} if they are not survived by ${spouseFullName}`,
        `Upon death of ${spouseFullName} if they are not survived by ${selfFullName}`,
        'Upon death of second settlor/grantor',
        `Upon death of ${selfFullName} if ${selfFullName} predeceases ${spouseFullName}`,
        `Upon death of ${spouseFullName} if ${spouseFullName} predeceases ${selfFullName}`,
        'Upon death of settlor/grantors',
        'Other',
    ];
    const beforeAnyDeathTriggerOptions = [
        `During the lifetime of (${selfFullName}), and before the death of (${spouseFullName})`,
        `During the lifetime of ${selfFullName}`,
        `During the lifetime of (${spouseFullName}), and before the death of (${selfFullName})`,
        `During the lifetime of ${spouseFullName}`,
    ];

    if (estateManualInput?.[docId]?.listsAssets !== 'Yes') {
        return <></>;
    }

    const addAssetBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputAsset(docId, id));
    };

    const removeAssetBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                docId,
                asset.id,
                ['assetType', 'primaryBeneficiaries', 'contingentBeneficiaries', 'whenWillTransfer'],
            ));
        }
    };

    const addBeneficiaryBtn_Click = (beneficiaryType, internalAssetId) => {
        return () => {
            const datapoint = `${beneficiaryType}Beneficiaries___${internalAssetId}`;
            const beneficiaryId = estateManualInput[docId][datapoint];

            let beneficiariesList = estateManualInput[docId][`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
            const contactsIds = beneficiariesList.map(b => b.contactId);

            if (!contactsIds.includes(beneficiaryId)) {
                beneficiariesList.push({ contactId: beneficiaryId, amountType: '%', amount: '0' });
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }

            dispatch(setEstateManualInputField(docId, `${beneficiaryType}Beneficiaries___${internalAssetId}`, ''));
        }
    }

    const removeBeneficiaryBtn_Click = (beneficiaryType, internalAssetId, contactId) => {
        return () => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);

            const index = beneficiariesList.indexOf(contactId);
            beneficiariesList.splice(index, 1);
            dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
        }
    }

    const beneficiaryAmount_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
    
            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            const amount = parseFloat(Math.abs(elem.value)).toString().replace(/^0+/, '');
            if (beneficiaryInList) {
                beneficiaryInList.amount = amount;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const beneficiaryComment_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
    
            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.comment = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const beneficiaryAmountType_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
    
            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.amountType = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    /* const beneficiaryAgeToReach_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
    
            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.ageToReach = elem.value;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    } */

    const beneficiaryPerStirpes_Change = (beneficiary, beneficiaryType, internalAssetId) => {
        return (e, elem) => {
            let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
            beneficiariesList = JSON.parse(beneficiariesList);
    
            const beneficiaryInList = beneficiariesList.find(b => b.contactId === beneficiary.contactId);
            if (beneficiaryInList) {
                beneficiaryInList.perStirpes = elem.checked;
                dispatch(setEstateManualInputField(docId, `${beneficiaryType}BeneficiariesList___${internalAssetId}`, JSON.stringify(beneficiariesList)));
            }
        }
    }

    const BeneficiariesList = (beneficiaryType, internalAssetId) => {
        let beneficiariesList = estateManualInput?.[docId]?.[`${beneficiaryType}BeneficiariesList___${internalAssetId}`] || '[]';
        beneficiariesList = JSON.parse(beneficiariesList);
        beneficiariesList = beneficiariesList?.map(beneficiary => ({ ...beneficiary, contact: client?.estateContactsList?.find(c => c._id === beneficiary.contactId) }));

        const blanckOption = [{ value: '', text: ' ' }];

        const fillOptions = [
            { value: '%', text: '%' },
            { value: '$', text: '$' },
        ];

        let amountTypeOptions = []
        if (
            estateManualInput[docId]?.[`assetId___${internalAssetId}`] === client?.estateAssetsList?.filter(
                itm => itm.name === 'Net Income and Principal'
            )[0]?._id
        ) {
            amountTypeOptions = [...blanckOption, ...fillOptions]
        } else {
            amountTypeOptions = [...fillOptions]
        }

        return (
            <div style={{ display: beneficiariesList?.length > 0 ? 'block' : 'none', margin: '10px 0 10px 50px' }}>
            {beneficiariesList.map(beneficiary => {
                return <>
                    <div style={{ padding: '10px', borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                        border: estateManualInputChange && docId in estateManualInputChange && `${beneficiaryType}BeneficiariesList___${internalAssetId}` in estateManualInputChange[docId] ? '1px solid rgba(21, 97, 198, 0.5)' : 'none',
                        borderRadius: '0.28571429rem',
                        boxShadow: estateManualInputChange && docId in estateManualInputChange && `${beneficiaryType}BeneficiariesList___${internalAssetId}` in estateManualInputChange[docId] ? 'rgba(21, 97, 198, 0.5) 0px 1px 10px' : 'none' 
                    }}>
                        <div>
                            {beneficiary.contact?.firstName} {beneficiary.contact?.lastName} [{beneficiary.contact?.relation}]
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {[2,3,4].includes(estateManualInputRequest.estateSnapshotVersion) &&
                            <div style={{ width: '100px', display: 'inline-block' }}>
                                <Checkbox
                                    checked={beneficiary.perStirpes}
                                    label='per stirpes'
                                    value={beneficiary.perStirpes}
                                    onChange={beneficiaryPerStirpes_Change(beneficiary, beneficiaryType, internalAssetId)}
                            />
                            </div>
                            }

                            <div style={{ width: '50px', display: 'inline-block' }}>
                                <Select fluid
                                    options={amountTypeOptions}
                                    defaultValue={beneficiary.amount !== "0" ? beneficiary.amountType : '%'}
                                    onChange={beneficiaryAmountType_Change(beneficiary, beneficiaryType, internalAssetId)}
                                />
                            </div>
                            <Input
                                style={{ width: '120px', margin: '0 30px 0 5px' }}
                                value={beneficiary.amount}
                                onChange={beneficiaryAmount_Change(beneficiary, beneficiaryType, internalAssetId)}
                                type="number"
                            />
                            <Icon name='delete' inverted color='black' size='large' onClick={removeBeneficiaryBtn_Click(beneficiaryType, internalAssetId, beneficiary.contact?._id)} />
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '10px 0',
                            borderBottom: '1px solid #e8e8e8',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            marginLeft: '119px',
                            columnGap: '16px',
                        }}
                    >
                        <div>
                            Comments:
                        </div>
                        <div style={{width: '100%'}}>
                            <TextArea
                                value={beneficiary.comment}
                                onChange={beneficiaryComment_Change(beneficiary, beneficiaryType, internalAssetId)}
                                placeholder="Write your comments"
                                style={{
                                    width: '100%',
                                    border: '1px solid rgba(34, 36, 38, 0.15)',
                                    padding: '0.7em 1em',
                                    borderRadius: '5px',
                                    '&::focus': {
                                        border: '1px solid #85b7d9'
                                    },
                                    resize: "vertical",
                                    fieldSizing: "content",
                                }}
                                maxLength={maxTextAreaLength}
                            />
                            <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '12px', color: '#9e9e9e', marginTop:"-7px" }}>
                                {beneficiary.comment?.length || 0}/{maxTextAreaLength}
                            </div>
                        </div>
                    </div>
                </>
            })}
        </div>
        );
    };

    const handlerReceiveAssets = (doc, field, val) => {
        const asset = field.split('___')[1];
        const actions = {
            [`remainderBeneficiariesReceiveAssetsOutright___${asset}`]: {
                Yes: () => {
                    dispatch(setEstateManualInputField(doc, field, 'Yes'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsInTrust___${asset}`, 'No'));
                    estateManualInput?.[doc]?.[`whatProvisionsAreThere___${asset}`]
                        && dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, undefined));
                },
                No: () => {
                    dispatch(setEstateManualInputField(doc, field, 'No'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsInTrust___${asset}`, 'Yes'));
                    dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, 'Until beneficiary reaches age'));
                },
            },
            [`remainderBeneficiariesReceiveAssetsInTrust___${asset}`]: {
                Yes: () => {
                    dispatch(setEstateManualInputField(doc, field, 'Yes'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsOutright___${asset}`, 'No'));
                    dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, 'Until beneficiary reaches age'));
                },
                No: () => {
                    dispatch(setEstateManualInputField(doc, field, 'No'));
                    dispatch(setEstateManualInputField(doc, `remainderBeneficiariesReceiveAssetsOutright___${asset}`, 'Yes'));
                    estateManualInput?.[doc]?.[`whatProvisionsAreThere___${asset}`]
                        && dispatch(setEstateManualInputField(doc, `whatProvisionsAreThere___${asset}`, undefined));
                    
                },
            },
        };
        actions[field][val]();
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            {trustAssets?.length > 0 &&
                <div>
                    {trustAssets?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', marginBottom: '10px' }}>
                                    <InputItem
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        nOptions={client?.estateAssetsList?.map(asset => ({ key: asset._id, value: asset._id, text: asset.name }))}
                                        field={`assetId___${asset.id}`}
                                        ignoreBorder
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeAssetBtn_Click(asset)}>Remove</Button>
                                </div>
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    nOptions={[{ key: 'distributionsMandatoryIncomeYes', value: 'Yes', text: 'Yes', }, { key: 'distributionsMandatoryIncomeNo', value: 'No', text: 'No', }]}
                                    field={`areDistributionsMandatoryIncomeAndInterest___${asset.id}`}
                                    label="Are distributions mandatory - income and interest?"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id)}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    nOptions={[{ key: 'istributionsMandatoryPrincipalYes', value: 'Yes', text: 'Yes', }, { key: 'istributionsMandatoryPrincipalNo', value: 'No', text: 'No', }]}
                                    field={`areDistributionsMandatoryPrincipal___${asset.id}`}
                                    label="Are distributions mandatory - principal?"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id)}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    options={['Annuity', 'Unitrust']}
                                    field={`annuityOrUnitrust___${asset.id}`}
                                    label="Annuity or Unitrust?"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id)}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    field={`annuityAmount___${asset.id}`}
                                    label="Annuity Amount ($)"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id) && !!(estateManualInput[docId]?.[`annuityOrUnitrust___${asset.id}`] === 'Annuity')}
                                    type="number"
                                    indent={16}
                                />
                                <InputItem
                                    docType={docId}
                                    style={{ marginRight: '10px' }}
                                    field={`unitrustPercentage___${asset.id}`}
                                    label="Unitrust Percentage (%)"
                                    version={version}
                                    visible={!!(estateManualInput[docId]?.[`assetId___${asset.id}`] === client?.estateAssetsList?.filter(itm => itm.name === 'Net Income and Principal')[0]?._id) && !!(estateManualInput[docId]?.[`annuityOrUnitrust___${asset.id}`] === 'Unitrust')}
                                    type="number"
                                    indent={16}
                                />
                                <h4>How will remainder beneficiaries receive assets?</h4>
                                <InputItem docType={docId} label='Outright?' field={`remainderBeneficiariesReceiveAssetsOutright___${asset.id}`}  version={[1,2,3,4]} options={['Yes', 'No']} customChange={handlerReceiveAssets} />
                                <InputItem docType={docId} label='In trust?' field={`remainderBeneficiariesReceiveAssetsInTrust___${asset.id}`}  version={[1,2,3,4]} options={['Yes', 'No']} customChange={handlerReceiveAssets} />
                                <InputItem
                                    docType={docId}
                                    label='What provisions are there?'
                                    field={`whatProvisionsAreThere___${asset.id}`}
                                    version={[1,2,3,4]}
                                    options={['Until beneficiary reaches age']}
                                    visible={!!(estateManualInput[docId]?.[`remainderBeneficiariesReceiveAssetsInTrust___${asset.id}`] === 'Yes')}
                                    indent={4}
                                />
                                <InputItem
                                    indent={8}
                                    label='Trust'
                                    withoutPages
                                    isRequired
                                    version={[3,4]}
                                    docType={docId}
                                    nOptions={trustNameOptions.filter(itm => itm.value !== '')}
                                    field={`whatTrustContainsProvisions___${asset.id}`}
                                    visible={!!(estateManualInput[docId]?.[`remainderBeneficiariesReceiveAssetsInTrust___${asset.id}`] === 'Yes')}
                                />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <SelectFamilyMember docType={docId} label='Primary beneficiaries' style={{ marginBottom: '0px', marginRight: '10px' }} field={`primaryBeneficiaries___${asset.id}`} version={version} message='Different Answer'/>
                                    <Button onClick={addBeneficiaryBtn_Click('primary', asset.id)}>Add</Button>
                                </div>
                                {BeneficiariesList('primary', asset.id)}

                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`}  version={[1,2]} withoutPages />
                                <InputItem docType={docId} label='Is it first death or second death of primary beneficiaries?' field={`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`} version={[3,4]} options={['First death', 'Second death', 'Before any death']} />
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`} version={[3,4]} options={firstDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`] === 'First death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`} version={[3,4]} options={secondDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`] === 'Second death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary___${asset.id}`} version={[3,4]} options={beforeAnyDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathPrimaryBeneficiaries___${asset.id}`] === 'Before any death')} addOptions />


                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', marginBottom: '10px' }}>
                                    <SelectFamilyMember docType={docId} label='Contingent beneficiaries' style={{ marginBottom: '0px', marginRight: '10px' }} field={`contingentBeneficiaries___${asset.id}`}  version={version} withoutPages />
                                    <Button onClick={addBeneficiaryBtn_Click('contingent', asset.id)}>Add</Button>
                                </div>
                                {BeneficiariesList('contingent', asset.id)}
                                <InputItem docType={docId} label='Is it first death or second death of contingent beneficiaries?' field={`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`} version={[3,4]} options={['First death', 'Second death', 'Before any death']} />
                                <InputItem docType={docId} label='When will it transfer to contingent beneficiaries?' field={`whenWillTransferContingentBeneficiary___${asset.id}`} version={[3,4]} options={firstDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`] === 'First death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to contingent beneficiaries?' field={`whenWillTransferContingentBeneficiary___${asset.id}`} version={[3,4]} options={secondDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`] === 'Second death')} addOptions />
                                <InputItem docType={docId} label='When will it transfer to contingent beneficiaries?' field={`whenWillTransferContingentBeneficiary___${asset.id}`} version={[3,4]} options={beforeAnyDeathTriggerOptions} indent={4} visible={!!(estateManualInput[docId]?.[`isItFirstDeathOrSecondDeathContingentBeneficiaries___${asset.id}`] === 'Before any death')} addOptions />


                                {estateManualInputRequest.estateSnapshotVersion !== 2 && 
                                    <InputItem docType={docId} multiline label='Additional information' field={`additionalInfo___${asset.id}`} ignoreBorder  version={version}/>
                                }
                            </Segment>
                        )
                    })}
                </div>
            }
            <Button style={{ marginTop: '10px' }} onClick={addAssetBtn_Click}>Add Asset</Button>
        </div>
    )
}

const TrustTrustees = ({ docId, version, type }) => {
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);

    const trustTrustees = estateManualInputAssets?.[`${docId}_trustees`];

    const addTrusteeBtn_Click = (trusteeType) => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_trustees`, id));
            dispatch(setEstateManualInputField(docId, `trusteeType___${id}`, trusteeType || 'trustee'));
        }
    };

    const removeTrusteeBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_trustees`,
                asset.id,
                ['trustee', 'trusteeType'],
                docId,
            ));
        }
    };

    const trusteeTypes = {
        'trustee': 'Trustee',
        'successive_trustee': 'Successor',
        'alternate_trustee': 'Alternate',
    };

    return (
        <div style={{ marginBottom: '20px' }}>
            <h4>Trustees</h4>
            {trustTrustees?.length > 0 &&
                <Segment.Group>
                    {trustTrustees?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <h4 style={{ margin: '10px 10px 0 0' }}>{trusteeTypes[estateManualInput?.[docId]?.[`trusteeType___${asset.id}`]]}</h4>
                                    <SelectFamilyMember
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        field={`trustee___${asset.id}`}
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeTrusteeBtn_Click(asset)}>Remove</Button>
                                </div>
                                <div>
                                    <InputItem
                                        docType={docId}
                                        label='Sole trustee or co-trustee?'
                                        field={`trusteeTrusteeOrCoTrustee___${asset.id}`}
                                        visible={!!(estateManualInput[docId]?.[`trustee___${asset.id}`])}
                                        options={['Trustee', 'Co-trustee']}
                                        version={[1,2,3,4]}
                                        withoutPages
                                        indent={4}
                                    />
                                    <InputItem
                                        docType={docId}
                                        label='Can trustees act individually?'
                                        field={`trusteeCanTrusteesActIndividually___${asset.id}`}
                                        visible={!!(estateManualInput[docId]?.[`trusteeTrusteeOrCoTrustee___${asset.id}`] === 'Co-trustee')}
                                        options={['Yes', 'No']}
                                        version={[1,2,3,4]}
                                        withoutPages
                                        indent={8}
                                    />
                                    <InputItem
                                        docType={docId}
                                        label='Can any co-trustee continue as sole trustee?'
                                        field={`trusteeCanAnyCoTrusteeContinueAsSoleTrustee___${asset.id}`}
                                        visible={!!(estateManualInput[docId]?.[`trusteeTrusteeOrCoTrustee___${asset.id}`] === 'Co-trustee')}
                                        options={['Yes', 'No']}
                                        version={[1,2,3,4]}
                                        withoutPages
                                        indent={8}
                                    />
                                </div>
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addTrusteeBtn_Click('trustee')}>Add Trustee</Button>
            <Button onClick={addTrusteeBtn_Click('successive_trustee')}>Add Successor Trustee</Button>
            {/* <Button onClick={addExecutorBtn_Click('alternate_trustee')}>Add Alternate Trustee</Button> */}
        </div>
    )
}

const TrustBeneficiaries = ({ docId, version }) => {
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInput = useSelector(state => state.estateManualInput);
    // eslint-disable-next-line
    // const client = useSelector(state => state.currentClient);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);

    const trustBeneficiaries = estateManualInputAssets?.[`${docId}_beneficiaries`];

    const addBeneficiaryBtn_Click = (beneficiaryType) => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_beneficiaries`, id));
            dispatch(setEstateManualInputField(docId, `beneficiaryType___${id}`, beneficiaryType || 'primary'));
        }
    };

    const removeBeneficiaryBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_beneficiaries`,
                asset.id,
                ['beneficiary', 'beneficiaryType'],
                docId,
            ));
        }
    };

    /* const beneficiaryTypes = {
        'primary_beneficiary': 'Primary',
        'contingent_beneficiary': 'Contingent',
    }; */

    return (
        <div style={{ marginBottom: '20px' }}>
            <h4>Resulting Trusts</h4>
            {trustBeneficiaries?.length > 0 &&
                <Segment.Group>
                    {trustBeneficiaries?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <h4 style={{ margin: '10px 10px 0 0' }}>Trust</h4>
                                    <SelectFamilyMember
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        field={`beneficiary___${asset.id}`}
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeBeneficiaryBtn_Click(asset)}>Remove</Button>
                                </div>
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addBeneficiaryBtn_Click('primary_beneficiary')}>Add Trust</Button>
            
            {
                // eslint-disable-next-line
                (estateManualInputRequest.estateSnapshotVersion && estateManualInputRequest.estateSnapshotVersion === 1) || (estateManualInput?.[docId]?.type === 'Living Trust Irrevocable') &&
                <Button onClick={addBeneficiaryBtn_Click('contingent_beneficiary')}>Add Contingent Beneficiary</Button>
            }
        </div>
    )
}

const AgeDistribution = ({quantity, setQuantity, docType, asset}) => {
    const dispatch = useDispatch();
    const estateManualInput = useSelector(state => state.estateManualInput);
    const rows = [];
    for (let i = 0; i < quantity; i+=1) {
        rows.push(1);
    }

    const removeLast = () => {
        if (quantity === 1) { return; }

        const val = estateManualInput?.[docType]?.[`descendantsBeneficiaryAgeDistribution___${asset}`] || [];
        if (val.length >= quantity) {
            val.pop();
            dispatch(setEstateManualInputField(docType, `descendantsBeneficiaryAgeDistribution___${asset}`, val));
        }

        setQuantity(prev => (prev === 1 ? 1 : prev - 1));
    }

    const changeVal = (type, value, ind) => {
        const val = estateManualInput?.[docType]?.[`descendantsBeneficiaryAgeDistribution___${asset}`] || [];
        if (val[ind]) {
            val[ind][type] = value;
        } else {
            val[ind] = { [type]: value };
        }
        dispatch(setEstateManualInputField(docType, `descendantsBeneficiaryAgeDistribution___${asset}`, val));
    }

    useEffect(() => {
        const val = estateManualInput?.[docType]?.[`descendantsBeneficiaryAgeDistribution___${asset}`] || []
        setQuantity(val.length || 1);
    }, []);

    return(
        <div
            style={{
                marginLeft: '135px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '16px',
            }}
        >
            {rows.map((itm, ind) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',
                        columnGap: '8px',
                        padding: '12px',
                        border: '1px solid rgba(34, 36, 38, 0.15)',
                        borderRadius: '11px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                        }}
                    >
                        Age:
                        <Input
                            type="number"
                            min="1"
                            max="100"
                            style={{width: '80px'}}
                            value={estateManualInput?.[docType]?.[`descendantsBeneficiaryAgeDistribution___${asset}`]?.[ind]?.age || ''}
                            onChange={(e, {value}) => changeVal('age', value, ind)}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                        }}
                    >
                        Percentage:
                        <Input
                            type="number"
                            min="0"
                            max="100"
                            style={{width: '80px'}}
                            value={estateManualInput?.[docType]?.[`descendantsBeneficiaryAgeDistribution___${asset}`]?.[ind]?.percentage || ''}
                            onChange={(e, {value}) => changeVal('percentage', value, ind)}
                        />
                    </div>
                </div>
            ))}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <Button color={'blue'} onClick={() => setQuantity(prev => prev + 1)}>Add</Button>
                {quantity >= 2 && <Button color={'red'} onClick={removeLast}>Remove</Button>}
            </div>
        </div>
    );
}

const DescendantsBeneficiaries = ({ docId, version }) => {
    const dispatch = useDispatch();
    const [ageDistrbution, setAgeDistrbution] = useState(1);
    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);

    const trustBeneficiaries = estateManualInputAssets?.[`${docId}_descendantsBeneficiaries`];

    const addBeneficiaryBtn_Click = () => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_descendantsBeneficiaries`, id));
        }
    };

    const removeBeneficiaryBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_descendantsBeneficiaries`,
                asset.id,
                ['descendantsBeneficiary'],
                docId,
            ));
        }
    };

    return(
        <div style={{ marginBottom: '20px' }}>
            <h4>Beneficiaries</h4>
            {trustBeneficiaries?.length > 0 &&
                <Segment.Group>
                    {trustBeneficiaries?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <p style={{ margin: '10px 10px 0 0' }}>Name</p>
                                    <SelectFamilyMember
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        field={`descendantsBeneficiary___${asset.id}`}
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeBeneficiaryBtn_Click(asset)}>Remove</Button>
                                </div>
                                <AgeDistribution quantity={ageDistrbution} setQuantity={setAgeDistrbution} docType={docId} asset={asset.id} />
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addBeneficiaryBtn_Click()}>Add Beneficiary</Button>
        </div>
    );
}

const TrustIncomeBeneficiaries = ({ docId, version }) => {
    const dispatch = useDispatch();

    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    // eslint-disable-next-line
    const estateManualInput = useSelector(state => state.estateManualInput);
    // const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);

    const trustIncomeBeneficiaries = estateManualInputAssets?.[`${docId}_incomeBeneficiaries`];

    const addIncomeBeneficiaryBtn_Click = () => {
        return () => {
            const id = uuid();
            dispatch(addEstateManualInputAsset(`${docId}_incomeBeneficiaries`, id));
        }
    };

    const removeIncomeBeneficiaryBtn_Click = (asset) => {
        return () => {
            dispatch(removeEstateManualInputAsset(
                `${docId}_incomeBeneficiaries`,
                asset.id,
                ['incomeBeneficiary'],
                docId,
            ));
        }
    };
    
    if(estateManualInputRequest.estateSnapshotVersion && !version.includes(estateManualInputRequest.estateSnapshotVersion) ) {
        return <></>
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            <h4>Income Beneficiaries</h4>
            {trustIncomeBeneficiaries?.length > 0 &&
                <Segment.Group>
                    {trustIncomeBeneficiaries?.map(asset => {
                        return (
                            <Segment key={asset.id}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <SelectFamilyMember
                                        docType={docId}
                                        style={{ marginBottom: '0px', marginRight: '10px' }}
                                        field={`incomeBeneficiary___${asset.id}`}
                                        version={version}
                                    />
                                    <Button color='red' onClick={removeIncomeBeneficiaryBtn_Click(asset)}>Remove</Button>
                                </div>
                            </Segment>
                        )
                    })}
                </Segment.Group>
            }
            <Button onClick={addIncomeBeneficiaryBtn_Click()}>Add Income Beneficiary</Button>
        </div>
    )
}

const OtherInformationBlocks = ({ docId, version }) => {
    const dispatch = useDispatch();
    const client = useSelector(state => state.currentClient);

    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputAssets = useSelector(state => state.estateManualInputAssets);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);

    const additionalInfoBlocks = estateManualInputAssets?.[`${docId}_additionalInfo`];

    const addBlockBtn_Click = () => {
        const id = uuid();
        dispatch(addEstateManualInputAsset(`${docId}_additionalInfo`, id));
    }

    const removeBlockBtn_Click = (blockId) => {
        return () => {
            dispatch(removeEstateManualInputAsset(`${docId}_additionalInfo`, blockId, ['content']));
        }
    }

    if (!version?.includes(estateManualInputRequest.estateSnapshotVersion)) {
        return <></>;
    }

    return (
        <div>
            <p>What other material information is contained in the document?</p>
            {additionalInfoBlocks?.map((block, i) => {
                return (<>
                    <InputItem
                        docType={docId}
                        label='Category'
                        field={`contentCategory___${block.id}`}
                        options={[
                            'At the end of the trust',
                            'Disclaimer clause',
                            'Simultaneous death clause',
                            'Generation skipping provision?',
                            'Spendthrift clause',
                            'Specific assets named in trust',
                            'Crummey Power',
                            'Other category',
                        ]}
                        addOptions
                        version={[3,4]}
                        indent={4}
                        rightComponent={() => {
                            return <Button color='red' style={{ marginLeft: '10px' }} onClick={removeBlockBtn_Click(block.id)}>
                                Remove
                            </Button>
                        }}
                    />
                    <InputItem
                        docType={docId}
                        label='When does this trust end?:'
                        field={`contentAtTheEndOfTheTrust___${block.id}`}
                        version={[3,4]}
                        type={'number'}
                        indent={8}
                        options={[
                            'Lifetime of settlor',
                            'Lifetime of someone else',
                        ]}
                        visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'At the end of the trust')}
                    />
                    <SelectFamilyMember
                        docType={docId}
                        label='Who?'
                        field={`contentLifetimeOfSomeoneElsePersons___${block.id}`}
                        version={[3,4]}
                        style={{ display: estateManualInput?.[docId]?.[`contentAtTheEndOfTheTrust___${block.id}`] === 'Lifetime of someone else' ? 'flex' : 'none'}}
                        indent={8}
                    />
                    <InputItem docType={docId} label='Term:' field={`contentTermOfTrust___${block.id}`} version={[3,4]} type={'number'} indent={8} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'At the end of the trust') && !!(estateManualInput?.[docId]?.[`contentAtTheEndOfTheTrust___${block.id}`] === 'Lifetime of someone else')} />
                    <InputItem docType={docId} label='Event:' field={`contentEventOfTrust___${block.id}`} version={[3,4]} multiline indent={8} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'At the end of the trust') && !!(estateManualInput?.[docId]?.[`contentAtTheEndOfTheTrust___${block.id}`] === 'Lifetime of someone else')} />
                    <SelectFamilyMember
                        docType={docId}
                        label="Who?"
                        field={`contentSimultaneousDeathClauseSersons___${block.id}`}
                        version={[3,4]}
                        style={{ display: estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'Simultaneous death clause' ? 'flex' : 'none'}}
                        indent={4}
                        only="self and spouse"
                    />
                    <InputItem docType={docId} label='Specific assets named in trust:' field={`contentAssetsNamedInTrust___${block.id}`} nOptions={client?.estateAssetsList?.map(asset => ({ key: asset._id, value: asset._id, text: asset.name }))} version={[3,4]} indent={4} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'Specific assets named in trust')} />
                    <InputItem docType={docId} label='Funding asset (%):' field={`contentAssetsNamedInTrustPercentage___${block.id}`} version={[3,4]} type="number" min={0} max={100} indent={8} visible={!!(estateManualInput?.[docId]?.[`contentCategory___${block.id}`] === 'Specific assets named in trust')} />
                    {/* <InputItem
                        docType={docId}
                        label='Comments'
                        field={`content___comments___${block.id}`}
                        version={[3,4]}
                        multiline
                        indent={4}
                        visible={
                            !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`])
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Lifetime of settlor')
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Lifetime of someone else')
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Simultaneous death clause')
                            && !!(estateManualInput?.[docId]?.[`content___whenDoesThisTrustEnd___${block.id}`] !== 'Specific assets named in trust')
                        }
                    /> */}
                    <InputItem
                        version={version}
                        key={block.id}
                        multiline={true}
                        docType={docId}
                        field={`content___${block.id}`}
                        label={`#${i + 1}`}
                        /* rightComponent={() => {
                            return <Button color='red' style={{ marginLeft: '10px' }} onClick={removeBlockBtn_Click(block.id)}>
                                Remove
                            </Button>
                        }} */
                    />
                </>)
            })}
            <Button onClick={addBlockBtn_Click}>Add paragraph</Button>
        </div>
    )
}

export const Trust = ({ docId, isJoint = false, ButtonMoveToJoint }) => {
    const docType = `${docId}`;
    const dispatch = useDispatch();
    const client = useSelector(state => state.currentClient);
    const estateManualInput = useSelector(state => state.estateManualInput);
    const estateManualInputRequest = useSelector(state => state.estateManualInputRequest);
    const [jointDistributionSpouse, setJointDistributionSpouse] = useState(50);
    const [jointDistributionSelf, setJointDistributionSelf] = useState(50);
    const [jointDistributionError, setJointDistributionError] = useState(false);

    const [showTrustsModal, setShowTrustsModal] = useState(false);

    const trustNameOptions = client?.estateContactsList
        ?.filter(c => c.relation === 'Trust')
        ?.map(c => ({ key: c._id, value: c._id, text: `${c.firstName}` }))
        || [];

    // const trustTypeOptions = ['ILIT', 'Living Trust Revocable', 'Living Trust Irrevocable', 'GRAT/GRUT', 'CRT', 'IDGT', 'QPRT', 'DAF', 'Dynasty Trust', 'Marital Trust A & B', 'SLAT', 'Testamentary'];
    const trustTypeOptions = ['Revocable', 'Irrevocable'];
    const trustRevocableOptions = [
        {
            key: "A/B or Disclaimer",
            text: "A/B or Disclaimer",
            value: "A/B or Disclaimer",
            disabled: true,
        },
        {
            key: "A/B/C or Disclaimer",
            text: "A/B/C or Disclaimer",
            value: "A/B/C or Disclaimer",
            disabled: true,
        },
        {
            key: "A/B Trust",
            text: "A/B Trust",
            value: "A/B Trust",
            disabled: false,
        },
        {
            key: "A/B/C Trust",
            text: "A/B/C Trust",
            value: "A/B/C Trust",
            disabled: false,
        },
        {
            key: "Disclaimer Trust",
            text: "Disclaimer Trust",
            value: "Disclaimer Trust",
            disabled: false,
        },
        {
            key: "General Revocable Outright",
            text: "General Revocable Outright",
            value: "General Revocable Outright",
            disabled: false,
        },
        {
            key: "General Revocable in Trust",
            text: "General Revocable in Trust",
            value: "General Revocable in Trust",
            disabled: false,
        },
        {
            key: "Survivor's Trust",
            text: "Survivor's Trust",
            value: "Survivor's Trust",
            disabled: false,
        },
    ];

    const trustIrrevocableOptions = [
        'header|Resulting Trusts',
        'B Trust',
        'A Trust',
        'State Exemption/C Trust',
        'Disclaimer Trust',
        'Children\'s/descendants Trust',
        'disabled|Family/B Trust',
        'disabled|Marital/A Trust',
        'header|Irrevocable',
        'General Irrevocable/Dynasty',
        'ILIT/SLAT',
        'GRAT',
        'GRUT',
        'CRT',
        'QPRT',
        'IDGT',
        'Credit Shelter Trust',
        'disabled|GRAT/GRUT',
    ];

    const spouse = client?.estateContactsList
        ?.filter(c => c.relation === 'Spouse')[0]
        || false;

    const self = client?.estateContactsList
        ?.filter(c => c.relation === 'Self')[0]
        || false;

    const statesOptions = [
        { key: '', value: '', text: '' },
        ...Object.keys(usStates).map(state => ({
            key: usStates[state],
            value: state,
            text: usStates[state],
        })),
    ];

    const handleChangeJoint = (doc, field, value) => {
        dispatch(setEstateManualInputField(doc, field, value));
        value === 'Single' && setJointDistributionSpouse(0) && setJointDistributionSelf(0);
    };

    useEffect(() => {
        if (estateManualInput[docId].jointOrSingle === 'Joint') {
            const value = [
                {
                    beneficiarieId: spouse._id,
                    type: '%',
                    percentage: jointDistributionSelf,
                    relation: 'Self',
                },
                {
                    beneficiarieId: self._id,
                    type: '%',
                    percentage: jointDistributionSpouse,
                    relation: 'Spouse',
                },
            ];
            dispatch(setEstateManualInputField(docType, 'jointDistribution', value));

            if (parseFloat(jointDistributionSpouse) + parseFloat(jointDistributionSelf) > 100) {
                setJointDistributionError(true);
            } else {
                setJointDistributionError(false);
            }
        } else {
            if (estateManualInput[docId]?.jointDistribution) {
                dispatch(removeEstateManualInputField(docType, 'jointDistribution'));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jointDistributionSpouse, jointDistributionSelf]);

    return (
        <>
            <InputItem checkbox label="Incomplete documents" withoutPages docType={docId} field='isDocumentIncomplete' version={[1,2,3,4]}/>
            <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ flex: 1, marginRight: '10px' }}>
                    <InputItem docType={docType} label='Trust name' field='name' nOptions={trustNameOptions} ignoreBorder version={[1,2,3,4]} withoutPages />
                </div>
                <Button onClick={() => setShowTrustsModal(true)}>Create trust</Button>
                <FamilyMembersModal open={showTrustsModal} closeBtn_Click={() => setShowTrustsModal(false)} type='trusts' />
            </div>
            <InputItem docType={docType} label='Trust type' field='type' options={trustTypeOptions} version={[1,2,3,4]} isRequired withoutPages />
            <InputItem docType={docType} label='Trust subtype' field='subtype' visible={!!(estateManualInput?.[docId]?.type === 'Revocable')} nOptions={trustRevocableOptions} version={[1,2,3,4]} isRequired withoutPages />
            <InputItem docType={docType} dropdown label='Trust subtype' field='subtype' visible={!!(estateManualInput?.[docId]?.type === 'Irrevocable')} options={trustIrrevocableOptions} version={[1,2,3,4]} isRequired withoutPages />

            <InputItem docType={docType} label='Is it GRAT or GRUT?' field='gratOrGrut' options={['GRAT', 'GRUT']} visible={estateManualInput?.[docId]?.type === 'GRAT/GRUT'} version={[1]} />
            <InputItem docType={docType} label='Is it CRAT or CRUT?' field='cratOrCrut' options={['CRAT', 'CRUT']} visible={estateManualInput?.[docId]?.type === 'CRT'} version={[1]}/>
            <InputItem docType={docType} label="What is the fair market value of the property contributed at inception?" field="whatIsTheFairMarketValueOfThePropertyContributedAtInception" indent={4} visible={estateManualInput?.[docId]?.type === 'GRAT/GRUT'} version={[1]}/>
            <InputItem docType={docType} label="What is the annual valuation date of the assets?" field="whatIsTheAnnualValuationDateOfTheAssets" indent={4} visible={estateManualInput?.[docId]?.type === 'GRAT/GRUT'} version={[1]} />

            {/* <InputItem docType={docType} label="What is the fair market value of the property contributed at inception?" field="whatIsTheFairMarketValueOfThePropertyContributedAtInception_crt" indent={4} visible={estateManualInput?.[docId]?.type === 'CRT'} />
            <InputItem docType={docType} label="What is the annual valuation date of the assets?" field="whatIsTheAnnualValuationDateOfTheAssets_crt" indent={4} visible={estateManualInput?.[docId]?.type === 'CRT'} /> */}

            <InputItem docType={docType} label='Date of Trust' field='dateOfTrust' version={[1,2,3,4]} withoutPages dateFormat />
            <InputItem
                docType={docId}
                label={`Governing Law`}
                field={`state`}
                nOptions={statesOptions}
                version={[4]}
            />
            <InputItem docType={docType} label='Is it a grantor or non-grantor trust?' field='grantorOrNonGrantorTrust' options={['Grantor', 'Non-grantor']} version={[1,2]} withoutPages />
            <InputItem docType={docType} indent={4} label='Grantor' field='grantor' visible={estateManualInput?.[docId]?.grantorOrNonGrantorTrust === 'Grantor'} version={[1,2]} />

            {estateManualInputRequest.estateSnapshotVersion >= 4 && !isJoint && estateManualInput?.[docId]?.jointOrSingle === 'Joint' &&
                <div style={{display: "flex", justifyContent:"end", marginBottom: "5px"}} >
                    {ButtonMoveToJoint}
                </div>
            }
            <InputItem docType={docType} label='Is it joint or single?' field='jointOrSingle' options={spouse ? ['Joint', 'Single'] : ['Single']} version={[3,4]} withoutPages customChange={handleChangeJoint} />
            <div
                style={{
                    display: estateManualInput?.[docId]?.jointOrSingle === 'Joint' ? 'flex' : 'none',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '10px',
                    borderBottom: '1px solid #f0f0f0',
                    marginBottom: '10px',
                    width: 'calc(100% - 270px)',
                    marginLeft: 'auto',
                }}
            >
                <span>{self.firstName} {self.lastName} [{self.relation}]</span>
                <div>
                    <div style={{ width: '30px', display: 'inline-block', textAlign: 'right' }}>%</div>
                    <Input
                        style={{ width: '100px', margin: 0, marginLeft: '16px', border: jointDistributionError ? '1px solid red' : '' }}
                        defaultValue={jointDistributionSelf}
                        onChange={(e) => setJointDistributionSelf(e.target.value)}
                    />
                </div>
            </div>
            <div
                style={{
                    display: estateManualInput?.[docId]?.jointOrSingle === 'Joint' ? 'flex' : 'none',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '10px',
                    borderBottom: '1px solid #f0f0f0',
                    marginBottom: '10px',
                    width: 'calc(100% - 270px)',
                    marginLeft: 'auto',
                }}
            >
                <span>{spouse.firstName} {spouse.lastName} [{spouse.relation}]</span>
                <div>
                    <div style={{ width: '30px', display: 'inline-block', textAlign: 'right' }}>%</div>
                    <Input
                        style={{ width: '100px', margin: 0, marginLeft: '16px', border: jointDistributionError ? '1px solid red' : '' }}
                        defaultValue={jointDistributionSpouse}
                        onChange={(e) => setJointDistributionSpouse(e.target.value)}
                    />
                </div>
            </div>
            <div style={{ display: jointDistributionError ? 'block' : 'none', marginBottom: '10px', color: 'red', width: 'calc(100% - 270px)', marginLeft: 'auto' }}>
                The total distribution should not exceed 100%.
            </div>

            {/* <InputItem docType={docType} label='Is it irrevocable?' field='isItIrrevocable' options={['Yes', 'No']} initialValue={'No'} /> */}
            <InputItem docType={docType} label='Is it a grantor trust?' field='grantorOrNonGrantorTrust' options={['Yes', 'No']} version={[1,2,3,4]} withoutPages />

            <SelectFamilyMember
                docType={docType}
                label='Grantor'
                field='grantor'
                version={[1,2,3,4]}
                style={{ display: estateManualInput?.[docId]?.grantorOrNonGrantorTrust === 'Yes' ? 'flex' : 'none'}}
                indent={4}
                only="self and spouse"
                multiple
            />


            {/* <InputItem docType={docType} label='Trustees' field='trustees' />
            <InputItem docType={docType} label='Successor trustees' field='successorTrustees' /> */}
            <TrustTrustees docId={docId} version={[1,2,3,4]}/>

            {estateManualInput?.[docId]?.subtype !== 'Children\'s/descendants Trust' && <TrustBeneficiaries docId={docId} version={[1,2,3,4]}/>}
            {estateManualInput?.[docId]?.subtype === 'Children\'s/descendants Trust' && <DescendantsBeneficiaries docId={docId} version={[3,4]} />}

            <InputItem docType={docId} label='When will it transfer to primary beneficiaries?' field={`whenWillTransferPrimaryBeneficiary`} version={[2,3,4]} visible={!!(estateManualInput?.[docId]?.subtype !== 'Children\'s/descendants Trust')} />

            <InputItem docType={docType} label='Does the trust list assets?' field='listsAssets' options={['Yes', 'No']} version={[1,2,3,4]} withoutPages />
            <TrustAssets docId={docId}  version={[1,2,3,4]}/>

            {/* <InputItem docType={docType} label='Primary benficiaries names' field='primaryBeneficariesNames' /> */}
            {/* <InputItem docType={docType} label='Contingent beneficiaries names' field='contingentBeneficiariesNames' /> */}

            <InputItem docType={docType} label='Is compensation of trustees addressed?' field='compensationOfTrusteesAddressed' options={['Yes', 'No']} initialValue={'No'} version={[1]}/>
            <InputItem docType={docType} label='Explain' indent={4} field='compensationOfTrusteesAddressedExplain' visible={estateManualInput?.[docId]?.compensationOfTrusteesAddressed === 'Yes'}  version={[1]}/>
            <InputItem docType={docType} label='Does it include Crummey power for beneficiaries?' options={['Yes', 'No']} initialValue='No' version={[1]}/>

            {/* <InputItem docType={docType} label='Is spouse a primary beneficiary?' field='spousePrimaryBeneficiary' options={['Yes', 'No']} initialValue='No' /> */}
            <SpousePrimaryBeneficiary docId={docId} version={[1]}/>

            {/* <InputItem docType={docType} label='Is trust a beneficiary?' field='isTrustABeneficiary' options={['Yes', 'No']} initialValue='No' /> */}
            <InputItem docType={docType} label='Does the document create a trust for special needs beneficiaries?' field='documentCreateTrustSpecialNeedsBeneficiaries' options={['Yes', 'No']} initialValue='No' version={[1]}/>

            {/* <InputItem docType={docType} label='Income beneficiary name' field='incomeBeneficiaryName' /> */}
            {/* <SelectFamilyMember docType={docType} label='Income beneficiary' field='incomeBeneficiaryName' /> */}
            <TrustIncomeBeneficiaries docId={docId}  version={[1]}/>

            <InputItem docType={docType} label='Does the trust have a spendthrift clause?' field='trustHasSpendthriftClause' options={['Yes', 'No']} initialValue='No' version={[1]} />
            <InputItem docType={docType} field='spendthriftClauseDetails' label='Details' indent={4} multiline visible={estateManualInput?.[docId]?.trustHasSpendthriftClause === 'Yes'} version={[1]}/>

            <InputItem docType={docType} label='Is there a simultaneous death clause?' field='simultaneousDeathClause' options={['Yes', 'No']} initialValue='No' version={[1]}/>
            <InputItem docType={docType} indent={4} label='Which spouse is to have died first?' field='whichSpouseFirstDeath' visible={estateManualInput?.[docId]?.simultaneousDeathClause === 'Yes'} version={[1]}/>

            <InputItem docType={docType} label='Where is the situs of the trust?' field='situsOfTheTrust' version={[1]}/>
            <InputItem docType={docType} label='Does it give someone the right to change the situs?' field='giveSomeoneTheRightToChangeTheSitus' options={['Yes', 'No']} version={[1]}/>
            <InputItem docType={docType} label="Does it address the grantor's possible disability?" field='addressGrantorsPossibleDisability' options={['Yes', 'No']} version={[1]}/>
            <InputItem docType={docType} indent={4} label="Explain" field='addressGrantorsPossibleDisabilityExplain' visible={estateManualInput?.[docId]?.addressGrantorsPossibleDisability === 'Yes'} version={[1]}/>
            
            <InputItem docType={docType} label="Is a trustee or beneficiary given the power to revoke the trust?" field='trusteeOrBeneficiaryGivenThePowerToRevokeTheTrust' options={['Yes', 'No']} version={[1]}/>
            <InputItem docType={docType} indent={4} label="Under which situation?" field='trusteeOrBeneficiaryGivenThePowerToRevokeTheTrustUnderWhichSituation' visible={estateManualInput?.[docId]?.trusteeOrBeneficiaryGivenThePowerToRevokeTheTrust === 'Yes'} version={[1]}/>

            <InputItem docType={docType} label='Is the grantor the exclusive trust beneficiary while living?' field='grantorExclusiveTrustBeneficiaryWhileLiving' options={['Yes', 'No']} version={[1]}/>

            <InputItem docType={docType}
                label="If the grantor dies before the term is over, where does the property go?"
                field="ifTheGrantorDiesBeforeTheTermIsOverWhereDoesThePropertyGo"
                options={['Grantor\'s estate', 'Named beneficiaries', 'Another trust', 'An LLC']}
                version={[1]}
            />

            <InputItem docType={docType} label='Is there a completed Schedule that identifies one or more policies that are owned by the trust?' field='completedScheduleIdentifiesPoliciesOwnedByTrust' options={['Yes', 'No']} initialValue='No' version={[1]}/>

            <InputItem docType={docType} label='Does the trust address payment of premiums, other issues specific to the collection of the insurance proceeds?' field='trustAddressPaymentOfPremiums' options={['Yes', 'No']} initialValue='No' version={[1]}/>
            <InputItem docType={docType} indent={4} label='Explain' field='trustAddressPaymentOfPremiumsExplain' visible={estateManualInput?.[docId]?.trustAddressPaymentOfPremiums === 'Yes'} version={[1]}/>

            <InputItem docType={docType} label="Does the trust provide for the disposition of the trust property after the grantor's death?" field='trustProvideDispositionOfTrustPropertyAfterGrantorsDeath' options={['Yes', 'No']} version={[1]}/>
            <InputItem docType={docType} indent={4} label="Explain" field='trustProvideDispositionOfTrustPropertyAfterGrantorsDeathExplain' visible={estateManualInput?.[docId]?.trustProvideDispositionOfTrustPropertyAfterGrantorsDeath === 'Yes'} version={[1]}/>

            <InputItem docType={docType}
                label="Does the trust have a clause addressing minors or incompetent beneficiaries?"
                field="doesTheTrustHaveAClauseAddressingMinorsOrIncompetentBeneficiaries"
                options={['Yes', 'No']}
                initialValue={'Yes'}
                version={[1]}
            />
            <InputItem docType={docType} label="Page #" field="doesTheTrustHaveAClauseAddressingMinorsOrIncompetentBeneficiariesPage" indent={4} visible={estateManualInput?.[docId]?.doesTheTrustHaveAClauseAddressingMinorsOrIncompetentBeneficiaries === 'Yes'} version={[1]}/>
            <InputItem docType={docType} label="Paragraph #" field="doesTheTrustHaveAClauseAddressingMinorsOrIncompetentBeneficiariesParagraph" indent={4} visible={estateManualInput?.[docId]?.doesTheTrustHaveAClauseAddressingMinorsOrIncompetentBeneficiaries === 'Yes'} version={[1]}/>

            <InputItem docType={docType} label="Does the trust have a powers clause?" field="doesTheTrustHaveAPowersClause" options={['Yes', 'No']} />
            <InputItem docType={docType} label="Page #" field="doesTheTrustHaveAPowersClausePage" indent={4} visible={estateManualInput?.[docId]?.doesTheTrustHaveAPowersClause === 'Yes'} version={[1]}/>
            <InputItem docType={docType} label="Paragraph #" field="doesTheTrustHaveAPowersClauseParagraph" indent={4} visible={estateManualInput?.[docId]?.doesTheTrustHaveAPowersClause === 'Yes'}version={[1]} />

            <InputItem docType={docType}
                label="Does the trust include a power of substitution for the grantor?"
                field="doesTheTrustIncludeAPowerOfSubstitutionForTheGrantor"
                options={['Yes', 'No']}
                version={[1]}
            />
            <InputItem docType={docType} label="Page #" field="doesTheTrustIncludeAPowerOfSubstitutionForTheGrantorPage" indent={4} visible={estateManualInput?.[docId]?.doesTheTrustIncludeAPowerOfSubstitutionForTheGrantor === 'Yes'} version={[1]}/>
            <InputItem docType={docType} label="Paragraph #" field="doesTheTrustIncludeAPowerOfSubstitutionForTheGrantorParagraph" indent={4} visible={estateManualInput?.[docId]?.doesTheTrustIncludeAPowerOfSubstitutionForTheGrantor === 'Yes'} version={[1]}/>

            <InputItem docType={docType} label='Add other material information not covered by questions above' field='otherMaterialInformation' multiline version={[1]}/>
            <OtherInformationBlocks docId={docType} version={[2,3,4]} />
        </>
    )
}