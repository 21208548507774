import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Menu, Dropdown } from 'semantic-ui-react'

const CompanySideMenu = ({ activeMenu, changeMenu }) => {
  const companyProfile = useSelector((state) => state.currentCompany);
  const [integrations, setIntegrations] = useState([]);
  const integrationsAvailable = ["salesforce"]; //* If exists another integration please add here to show
  const me = useSelector(state => state.me);


  const dropStyle = {
    padding: 0,
    fontSize: '12px',
    height: '24px',
    margin: 0,
  };

  useEffect(() => {
    const keysIntegrations = Object.keys(companyProfile?._integrations || {});
    let keysFinal = keysIntegrations.filter(ki => integrationsAvailable.includes(ki));
    setIntegrations(keysFinal)

    // eslint-disable-next-line
  },[companyProfile])

  return (<>
    {activeMenu && <>
      <Menu vertical style={{ marginBottom: 0 }}>
        <Menu.Item>
          <div style={{ width: '100%', display:"flex", justifyContent:"center", textAlign:"center" }}>
            <div style={{display: "grid", gap:"10px"}}>
              <div>
                <img alt={companyProfile?.name} src={companyProfile?.logo} style={{width: "150px", height:"auto", margin: '0 auto'}} />
              </div>
              <div className="fp-alpha-highlight truncate-text" style={{fontWeight:"bold"}}>
                {companyProfile?.name}
              </div>
            </div>
          </div>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Information</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name="General Company Information"
              active={activeMenu === "general"}
              onClick={() => {changeMenu('general')}}
            />
          </Menu.Menu>
        </Menu.Item>

        <Menu.Item>
          <Menu.Header>Enterprise Setting</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name="Advisor Groups"
              active={activeMenu === "advisorGroup"}
              onClick={() => {changeMenu('advisorGroup')}}
            />
            { me?.permissions?.includes("companies:show_permissions_tab") &&
              <Menu.Item
                name="Permissions"
                active={activeMenu === "enterprisePermissions"}
                onClick={() => {changeMenu('enterprisePermissions')}}
              />
            }
            { companyProfile?.isMainOffice &&
              <Menu.Item
                name="Offices"
                active={activeMenu === "offices"}
                onClick={() => {changeMenu('offices')}}
              />
            }
          </Menu.Menu>
        </Menu.Item>

        <Menu.Item>
          <Menu.Header>Advisors</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name="Advisors List"
              active={activeMenu === "advisors"}
              onClick={() => {changeMenu('advisors')}}
            />
          </Menu.Menu>
        </Menu.Item>

        <Menu.Item id="company-profile-submenu">
          <Menu.Header>Credits</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              name="General"
              active={activeMenu === "creditsGeneral"}
              onClick={() => {changeMenu('creditsGeneral')}}
            />

            <Menu.Item style={{ height: '24px' }}>
              <Dropdown item text="Fulltax" style={dropStyle}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    active={activeMenu === 'fulltaxRequests'}
                    onClick={() => {changeMenu('fulltaxRequests')}}
                  >
                    Requests
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={activeMenu === 'fulltaxPurchased'}
                    onClick={() => {changeMenu('fulltaxPurchased')}}
                  >
                    Purchased
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item style={{ height: '24px' }}>
              <Dropdown item text="Estate" style={dropStyle}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    active={activeMenu === 'estateRequests'}
                    onClick={() => {changeMenu('estateRequests')}}
                  >
                    Requests
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={activeMenu === 'estatePurchased'}
                    onClick={() => {changeMenu('estatePurchased')}}
                  >
                    Purchased
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item style={{ height: '24px' }}>
              <Dropdown item text="Home" style={dropStyle}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    active={activeMenu === 'homeRequests'}
                    onClick={() => {changeMenu('homeRequests')}}
                  >
                    Requests
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={activeMenu === 'homePurchased'}
                    onClick={() => {changeMenu('homePurchased')}}
                  >
                    Purchased
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>

            <Menu.Item style={{ height: '24px' }}>
              <Dropdown item text="Auto" style={dropStyle}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    active={activeMenu === 'autoRequests'}
                    onClick={() => {changeMenu('autoRequests')}}
                  >
                    Requests
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={activeMenu === 'autoPurchased'}
                    onClick={() => {changeMenu('autoPurchased')}}
                  >
                    Purchased
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>

        {integrations.length > 0 && <Menu.Item>
          <Menu.Header>Integrations</Menu.Header>
          <Menu.Menu>
            {integrations.map((integration, index) => (
              <Menu.Item
                key={index}
                name={integration}
                active={activeMenu === `integration_${integration}`}
                onClick={() => {changeMenu(`integration_${integration}`)}}
              />
            ))}
          </Menu.Menu>
        </Menu.Item>}
      </Menu>
    </>}
  </>);
}

export default CompanySideMenu;
