import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseError } from "../../../../helpers";
import { advisorAPI } from "../../../../apis/AdvisorsAPI";
import { Button, Icon } from "semantic-ui-react";
import { AddCompanyModal } from "../../../modal/AddCompanyModal";
import { Link } from "react-router-dom";

const Offices = () => {
  const dispatch = useDispatch();
  const companyProfile = useSelector((state) => state.currentCompany);
  const [loading, setLoading] = useState(true);
  const [modalCreateCompany, setModalCreateCompany] = useState(false);
  const [offices, setOffices] = useState([]);

  const getOffices = async () => {
    try {
      const {data} = await advisorAPI.get(`/companies/settings/offices`, {params: {
        companyId: companyProfile?._id
      }})
      console.log(data)
      setOffices(data.offices)
    } catch (error) {
      responseError(error, undefined, "getOffices", dispatch)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(companyProfile?._id && companyProfile?.isMainOffice){
      getOffices()
    }

    // eslint-disable-next-line
  },[companyProfile?._id])

  if(!companyProfile?.isMainOffice){
    return <>This company is not a main company</>
  }
  return <>
    <div style={{display: "flex", justifyContent: "space-between", marginBottom: "10px"}}>
      <div>
        <h2>Offices</h2>
      </div>
      <div>
        <Button color="green" onClick={() => setModalCreateCompany(!modalCreateCompany)}>Add Office</Button>
      </div>
    </div>
    <div>
      {loading && <><Icon loading name="asterisk" /> Loading ...</>}
    </div>
    <div className="flex-gap-5" style={{ gap: '15px' }}>
      {offices.map((office, index)  => <div key={index}>
        <CardOffice item={office} />
      </div>)}
    </div>

    <AddCompanyModal modalState={modalCreateCompany} setModalState={setModalCreateCompany} mainOfficeId={companyProfile?._id} refreshFunction={getOffices} />
  </>
}

const CardOffice = ({item}) => {
  return <Link color="black" to={`/dashboard/companies/${item._id}`} >
    <div class="card_office">
      <div class="card-image_office">
        <img src={item.logo} alt={item.name} />
      </div>
      <div class="card-content_office">
        <h3>{item.name}</h3>
        <p>Total Advisors: {item?.advisorList?.length}</p>
        <p>Super Admin: {item?.superAdmin?.fullName || "---"}</p>
        {/* <div>
          <div>{item?.mainContact?.name}</div>
          <div>{item?.mainContact?.email}</div>
        </div> */}
      </div>
    </div>
  </Link>
}

export default Offices